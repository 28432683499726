import React, { useState } from "react"
import css from "@styled-system/css"
import { AnimatePresence, motion } from "framer-motion"

import { mdiClose } from "@mdi/js"

import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  FadeText,
  Flex,
  H1,
  H6,
  Icon,
  Text,
} from "components"

import mouseCursor from "../../../static/images/mouseCursor.png"

const mqIphoneX = `@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)`

function SilosSection({ title, subtitle, image, mobileImage }) {
  return (
    <Flex
      as="section"
      position="relative"
      pb={[5, 0]}
      bg="#F2F2F2"
      css={css({
        width: "100%",
        height: ["auto", "1500px", "1100px"],
        flexDirection: "column",
        overflow: "hidden"
      })}
      zIndex={-2}
    >

      <AnimatePresence>
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.7,
              },
            },
            exit: {
              opacity: 0,
              transition: { duration: 0.7 },
            },
          }}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <Container pt={[24, 96]}>
            <Column left maxWidth={780}>
              <H1 as="h1">
                <DunkText
                  lines={title ? title.split("\n") : []}
                  animated={true}
                />
              </H1>
              <FadeText delay={1}>
                <Text
                  children={subtitle}
                  mt={[3, 4]}
                  fontFamily="body"
                  fontSize={[4, 5]}
                />
              </FadeText>
            </Column>

          </Container>
        </motion.div>
      </AnimatePresence>
      <Box css={css({
        backgroundImage:
          ["url(" + mobileImage + ")", "url(" + mobileImage + ")", "url(" + image + ")"],
        backgroundSize: ["contain", "cover", "contain", "contain"],
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: ["calc(100% + 72px)", "100%", "calc(100% - 72px)", "calc(100% - 72px)"],
        height: ["660px", "1000px", "100%"],
        position: ["relative", "relative", "absolute"],
        right: [null, null, 0],
        bottom: [null, null, 0],
        top: ["0px", "0px", "100px", "100px"],
        marginTop: ["40px", "40px", "0px", "0px"],
        left: ["-24px", 0, null],
        zIndex: -1
      })}>
      </Box>
    </Flex>
  )
}

export default SilosSection
