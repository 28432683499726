import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Button, Column, DunkText, H1, Text, Container } from "components"

const EchoVideo = ({
  title,
  subtitle,
  buttonName,
  buttonUrl,
  video,
  youtubeUrl,
  useYoutube,
  scrollAmount,
  firstImage,
  secondImage,
  thirdImage
}) => {
  return (
    <Box position="relative" width="100%" bg="#F2F2F2">
      <Box
        display="flex"
        flexDirection={["column", "column", "column", "row"]}
        width="100%"
        justifyContent="space-between"
        py={["65px", "180px"]}
        position="relative"
      >
        <Box css={css({
          background:
            "url(" +
            firstImage +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          width: ["200px", "200px", "280px"],
          height: ["160px", "160px", "260px"],
          zIndex: 0,
          top: ["140px", "140px", "30px"],
          left: [`calc((100px) * ${(1 - scrollAmount)})`, 0, `calc((1000px) * ${(1 - scrollAmount)})`]
        })} />
        <Box css={css({
          background:
            "url(" +
            secondImage +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          width: "140px",
          height: "120px",
          zIndex: 0,
          top: "30px",
          left: [`calc(100px + (200px) * ${(1 - scrollAmount)})`, null, `calc(300px + (1000px) * ${(1 - scrollAmount)})`],
          right: [100, 100, null]
        })} />
        <Box css={css({
          background:
            "url(" +
            thirdImage +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          width: "100px",
          height: "80px",
          zIndex: 0,
          top: "120px",
          left: [`calc(200px + (250px) * ${(1 - scrollAmount)})`, null, `calc(450px + (1000px) * ${(1 - scrollAmount)})`],
          right: [10, 10, null]
        })} />
        <Container zIndex={2}>
          <Box
            display="flex"
            flexDirection={["column-reverse", "column-reverse", "column-reverse", "column-reverse"]}
            width="100%"
            justifyContent="space-between"
            css={css({
              columnGap: "24px",
              rowGap: "24px",
              "@media(min-width: 1400px)": {
                flexDirection: "row"
              },
            })}
          >
            <Box
              minHeight="100%"
              width={["100%", "100%", "100%", "50%"]}
              p={[0, 0, 0, 0]}
              css={css({
                "@media screen and (max-width: 1400px)": {
                  width: "100%",
                  margin: "auto",
                },
              })}
            >
              <div
                css={css({
                  position: "relative",
                  height: "0",
                  pb: "56.25%",
                })}
              >
                <iframe
                  src={useYoutube ? youtubeUrl : video}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  css={css({
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                  })}
                />
              </div>
            </Box>
            <Column
              left
              pb={["40px", "40px", "40px", "0"]}
              pr={[0, 0, 0, 4]}
              css={css({
                "@media screen and (max-width: 1400px)": {
                  paddingBottom: "40px",
                },
              })}
            >
              <H1 as="h2">
                <DunkText lines={title ? title.split("\n") : []} />
              </H1>
              <Text
                children={subtitle}
                mt={[3, 4]}
                fontFamily="body"
                fontSize={[4, 5]}
              />
              <Button children={buttonName} as={Link} to={buttonUrl} mt={4} />
            </Column>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default EchoVideo
