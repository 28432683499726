import React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import ScrollChanger from "react-scroll-changer"
import { Box, Column, Container, DunkText, H1, SEO, Text } from "components"
import HeroSection from "../_sections/_hero"
import TrailerVideoSection from "../_sections/_trailerVideo"
import EchoVideo from "../_sections/_echoVideo"
import HeroWithSecondarySection from "../_sections/_heroWithSecondary"
import SilosSection from "../_sections/_silos"

const PrivacyPolicyPage = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsEcoPage {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          heroImageEcho {
            url
          }
          heroVideoEcho {
            url
          }
          useStaticImageInHeroEcho
          heroSectionLogoEcho {
            url
          }
          heroTitleEcho
          heroSubtitleEcho
          titleVideoSectionEcho
          copyVideoSectionEcho
          videoSectionButtonUrlEcho
          videoSectionButtonNameEcho
          useYoutubeVideoEcho
          videoSectionYoutubeUrlEcho 
          videoVideoSectionEcho {
            url
          }
          animatedIcon1Echo {
            url
          }
          animatedIcon2Echo {
            url
          }
          animatedIcon3Echo {
            url
          }
          titleSection3Echo
          copySection3Echo
          section3ButtonNameEcho
          section3ButtonUrlEcho
          backgroundImageSection3Echo {
            url
          }
          foregroundImageSection3Echo {
            url
          }
          mobileImageSection3Echo {
            url
          }
          titleSection4Echo
          copySection4Echo
          imageSection4Echo {
            url
          }
          mobileImageSection4Echo {
            url
          }
        }
      }
    `}
    render={data => (
      <>
        <HelmetDatoCms seo={data.datoCmsEcoPage.seoMetaTags}>
          <meta name="keywords" content={data.datoCmsEcoPage.seoKeywords} />
        </HelmetDatoCms>
        <HeroSection
          image={data.datoCmsEcoPage.heroImageEcho.url}
          title={data.datoCmsEcoPage.heroTitleEcho}
          subtitle={data.datoCmsEcoPage.heroSubtitleEcho}
          logoImage={data.datoCmsEcoPage.heroSectionLogoEcho.url}
          video={data.datoCmsEcoPage.heroVideoEcho.url}
          useImage={data.datoCmsEcoPage.useStaticImageInHeroEcho}
        />
        <ScrollChanger
          start={1}
          end={0}
          length={1}
          attached={true}
          wrapperStyle={{
            height: "auto"
          }}
          style={{ height: "auto" }}
          children={<EchoVideo
            title={data.datoCmsEcoPage.titleVideoSectionEcho}
            subtitle={data.datoCmsEcoPage.copyVideoSectionEcho}
            buttonName={data.datoCmsEcoPage.videoSectionButtonNameEcho}
            buttonUrl={data.datoCmsEcoPage.videoSectionButtonUrlEcho}
            video={data.datoCmsEcoPage.videoVideoSectionEcho.url}
            youtubeUrl={data.datoCmsEcoPage.videoSectionYoutubeUrlEcho}
            useYoutube={data.datoCmsEcoPage.useYoutubeVideoEcho}
            firstImage={data.datoCmsEcoPage.animatedIcon1Echo.url}
            secondImage={data.datoCmsEcoPage.animatedIcon2Echo.url}
            thirdImage={data.datoCmsEcoPage.animatedIcon3Echo.url}
          />
          }
        />

        <ScrollChanger
          start={1}
          end={0}
          length={1}
          attached={false}
          wrapperStyle={{
            height: "auto"
          }}
          style={{ height: "auto" }}
          children={<HeroWithSecondarySection
            image={data.datoCmsEcoPage.backgroundImageSection3Echo.url}
            secondaryImage={data.datoCmsEcoPage.foregroundImageSection3Echo.url}
            mobileImage={data.datoCmsEcoPage.mobileImageSection3Echo.url}
            title={data.datoCmsEcoPage.titleSection3Echo}
            subtitle={data.datoCmsEcoPage.copySection3Echo}
            buttonText={data.datoCmsEcoPage.copySection3Echo.section3ButtonNameEcho}
            buttonUrl={data.datoCmsEcoPage.copySection3Echo.section3ButtonUrlEcho}
          />
          }
        />

        <SilosSection
          image={data.datoCmsEcoPage.imageSection4Echo.url}
          mobileImage={data.datoCmsEcoPage.mobileImageSection4Echo.url}
          title={data.datoCmsEcoPage.titleSection4Echo}
          subtitle={data.datoCmsEcoPage.copySection4Echo}
        />
      </>
    )}
  />
)

export default PrivacyPolicyPage
