import React, { useState } from "react"
import css from "@styled-system/css"
import { AnimatePresence, motion } from "framer-motion"

import { mdiClose } from "@mdi/js"

import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  FadeText,
  Flex,
  H1,
  H6,
  Icon,
  Text,
} from "components"

import mouseCursor from "../../../static/images/mouseCursor.png"

const mqIphoneX = `@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)`

function HeroWithSecondarySection({
  buttonText,
  title,
  subtitle,
  image,
  buttonUrl,
  secondaryImage,
  scrollAmount,
  mobileImage
}) {

  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent={["center", "center"]}
      height={["400px", "600px", "100vh"]}
      pb={[5, 0]}
      css={css({
      })}
      bg="#F2F2F2"
      zIndex={-2}
    >
      <Box css={css({
        backgroundImage:
          ["linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" + mobileImage + ")",
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" + mobileImage + ")",
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" + image + ")"],
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
        width: ["100%", "100%", "calc(100% - 200px)"],
        height: ["400px", "600px", "100vh"],
        zIndex: -1
      })}>
      </Box>
      <Box

        css={css({
          display: ["none", "none", "block"],
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" +
            secondaryImage +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          width: ["400px", "400px", "400px", "600px"],
          height: ["270px", "270px", "270px", "400px"],
          zIndex: -1,
          right: "0px",
          top: `calc((100vh - 400px) * ${(1 - scrollAmount)})`
        })}>
      </Box>
      <AnimatePresence>
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.7,
              },
            },
            exit: {
              opacity: 0,
              transition: { duration: 0.7 },
            },
          }}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <Container minHeight={["33%", 0]} pt={[96, 96, 120]} css={css({

            zIndex: 1,

          })}>
            <Column left min>
              <H1 as="h1" color="textReverse">
                <DunkText
                  lines={title ? title.split("\n") : []}
                  animated={true}
                />
              </H1>
              <FadeText delay={1}>
                <Text
                  children={subtitle}
                  mt={[3, 4]}
                  fontFamily="body"
                  fontSize={[4, 5]}
                  color="textReverse"
                />
                {buttonText && (
                  <Box
                    mt={[3, 4]}
                    css={{
                      display: "block",

                    }}
                  >
                    <Button
                      children={"Link to future real ppl echo vid?"}
                      as={"a"}
                      href={buttonUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={{ pointerEvents: "all" }}
                      onClick={event => {
                        event.stopPropagation()
                      }}
                    />
                  </Box>
                )}
              </FadeText>
            </Column>
          </Container>
        </motion.div>
      </AnimatePresence>
    </Flex >
  )
}

export default HeroWithSecondarySection
